import Bowser from 'bowser'
import { boolean } from 'boolean'

export default class BowserAccessor {
  static bowser = Bowser.detect(window.navigator.userAgent)

  static get msie() {
    return this.bowser.msie
  }

  static get mobile() {
    return this.bowser.mobile
  }

  static get booleanMobile() {
    return boolean(this.mobile)
  }

  static checkIsIos() {
    // FIXME Bowser.detect で返された instance ではなく Bowser を使っているのはなぜ？
    return this.bowser.ios || (this.bowser.safari && document.ontouchstart !== undefined)
  }

  static checkIpad() {
    return document.ontouchstart !== undefined
        && (
          this.bowser.mac
          || (this.bowser.chrome && this.bowser.ios)
        )
  }

  // iPadOS 16以上では問題ないが、15以下だとHLSの再生時に不具合がある可能性があるため判定できるようにした
  static checkIsOldIpad() {
    return document.ontouchstart !== undefined
          && (
            (this.bowser.mac && Number(this.bowser.version) < 16)       // iPad Safari
            || (this.bowser.ios && Number(this.bowser.osversion) < 16)  // iPad Chrome
          )
  }
}
