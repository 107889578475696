// @ts-nocheck
import * as THREE from 'three'
import { Face3, Geometry } from 'three-stdlib/deprecated/Geometry'

/**
 * 型判定
 * String, Number, Boolean, Array, Object, Function, Date, RegExp, Error
 */
function is(type: string, obj: any): boolean {
  const klass = Object.prototype.toString.call(obj).slice(8, -1)
  return obj !== undefined && obj !== null && klass === type
}

function prepare(url: string, values: { [key: string]: any }): string {
  for (const key in values) {
    url = url.replace(':' + key, values[key])
  }

  return url
}

/**
 * FIXME: ?. と ?? を使ったパターンに置き換える。
 * dig(this.meta_data, 'common.processing_tags', [])
 * this.meta_data?.common?.processing_tags ?? []
 */
function dig(hash: any, key: string, defaultValue?: any): any {
  let value = hash
  const keys = key.split('.')
  for (let i = 0, l = keys.length; i < l; i++) {
    if (value === undefined || value === null) break
    value = value[keys[i]]
  }
  return value !== undefined && value !== null ? value : defaultValue
}

function isImageType(type: string): boolean {
  const imageTypes = [
    'image',
    'celestial_sphere_image',
    'fisheye_250_image',
    'swing_image',
    'fisheye_180_image',
    'chipping_fisheye_image',
  ]
  return imageTypes.indexOf(type) !== -1
}

function isStaticImageType(type: string): boolean {
  const imageTypes = [
    'image',
    'swing_image',
  ]
  return imageTypes.indexOf(type) !== -1
}

function isVrImageType(type: string): boolean {
  const imageTypes = [
    'celestial_sphere_image',
    'fisheye_250_image',
    'fisheye_180_image',
    'chipping_fisheye_image',
  ]
  return imageTypes.indexOf(type) !== -1
}

function isVideoType(type: string): boolean {
  const videoTypes = [
    'video',
    'celestial_sphere_video',
    'fisheye_250_video'
  ]
  return videoTypes.indexOf(type) !== -1
}

function isVrVideoType(type: string): boolean {
  const imageTypes = [
    'celestial_sphere_video',
    'fisheye_250_video'
  ]
  return imageTypes.indexOf(type) !== -1
}

function isStaticVideoType(type: string): boolean {
  const videoTypes = [
    'video'
  ]
  return videoTypes.indexOf(type) !== -1
}

function createFisheyeGeometory(
  angleDegree: number,
  radius: number,
  numDivide: number,
  uvScale: THREE.Vector2
): THREE.BufferGeometry {
  const geometry = new Geometry()

  const angA = (angleDegree / 2 / 180) * Math.PI

  const dividedAngles = times(numDivide, n => {
    return n * ((2 * Math.PI) / numDivide)
  })

  const crossCircles = times(numDivide, n => {
    const ang = (n * angA) / numDivide
    return { r: radius * Math.sin(ang), z: radius * Math.cos(ang) }
  })

  let nIdx = 0
  const idxVertexes = []

  // create vertex
  // 頂点座標の計算
  crossCircles.forEach(crossCircle => {
    const aroundIdx = []
    if (Math.abs(crossCircle.r) < 0.0000001) {
      geometry.vertices.push(new THREE.Vector3(0, 0, crossCircle.z))
      idxVertexes.push([nIdx])
      nIdx += 1
      return
    }

    dividedAngles.forEach(ang => {
      const x = crossCircle.r * Math.cos(ang)
      const y = crossCircle.r * Math.sin(ang)
      aroundIdx.push(nIdx)
      nIdx += 1
      geometry.vertices.push(new THREE.Vector3(x, y, crossCircle.z))
    })

    idxVertexes.push(aroundIdx)
  })

  // create face
  // 法線方向が外側になるように反時計回りに設定
  let m
  for (let n = 0; n < idxVertexes.length - 1; ++n) {
    const indexes = idxVertexes[n]
    if (n > idxVertexes.length - 2) {
      continue
    }
    const nextIndxes = idxVertexes[n + 1]
    if (n === 0) {
      const p1 = indexes[0]
      for (m = 0; m < nextIndxes.length; ++m) {
        const p2 = nextIndxes[m]
        const p3 = nextIndxes[(m + 1) % nextIndxes.length]
        const face = new Face3(p1, p2, p3)
        geometry.faces.push(face)
      }
    } else if (indexes.length === nextIndxes.length) {
      for (m = 0; m < indexes.length; ++m) {
        const p11 = indexes[m]
        const p12 = nextIndxes[m % nextIndxes.length]
        const p13 = nextIndxes[(m + 1) % nextIndxes.length]
        const face1 = new Face3(p11, p12, p13)
        geometry.faces.push(face1)

        const p23 = nextIndxes[(m + 1) % nextIndxes.length]
        const p22 = indexes[m]
        const p21 = indexes[(m + 1) % nextIndxes.length]
        const face2 = new Face3(p21, p22, p23)
        geometry.faces.push(face2)
      }
    }
  }

  // Create UVmap
  // UVマッピング
  const uvMaps = []
  // 2D座標作成
  const uvRadiusMap = times(numDivide, n => {
    return n / numDivide
  })

  uvRadiusMap.forEach(r => {
    if (r > 0) {
      for (let m = 0; m < dividedAngles.length; ++m) {
        const ang = dividedAngles[m]
        const x = (r / 2) * Math.cos(ang) * uvScale.x + 0.5
        const y = (r / 2) * Math.sin(ang) * uvScale.y + 0.5
        uvMaps.push(new THREE.Vector2(x, y))
      }
    } else {
      uvMaps.push(new THREE.Vector2(0.5, 0.5))
    }
  })

  // マッピング
  geometry.faces.forEach(face => {
    geometry.faceVertexUvs[0].push([
      uvMaps[face.a],
      uvMaps[face.b],
      uvMaps[face.c],
    ])
  })
  geometry.uvsNeedUpdate = true

  return geometry.toBufferGeometry()
}

function range(start, end) {
  if(start > end) return []
  return Array(end - start + 1).fill(true).map((v,k) => k + start)
}

function times(times, callback) {
  if(times <=0) return []
  return range(0, times - 1).map(i => callback(i))
}

function shuffle(array) {
  return array.sort(()=> Math.random() - 0.5)
}

function sample(array) {
  const index = Math.floor(Math.random() * array.length)
  return array[index]
}

export {
  is,
  prepare,
  dig,
  isImageType,
  isStaticImageType,
  isVrImageType,
  isVideoType,
  isVrVideoType,
  isStaticVideoType,
  createFisheyeGeometory,
  range,
  times,
  shuffle,
  sample
}
